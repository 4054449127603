<template>
  <div class="centro">
    <v-card
      outlined
      id="cartao"
      class="mx-auto elevation-10 mb-2"
    >
      <v-card-title class="mb-10">
        <svg-icon height="50px" width="50px" type="mdi" :path="icone"></svg-icon>
        <h3 v-if="this.$route.fullPath.substring(0, 12) == '/bairros/new'">Cadastro de Bairros de {{ cidade.nome }}-{{ cidade.uf }} (Inclusão)</h3>
        <h3 v-else>Cadastro de Bairros de {{ cidade.nome }}-{{ cidade.uf }} (Alteração)</h3>
      </v-card-title>
      <form id="formulario">
        <v-row>
          <v-col cols="2">
            <v-text-field 
              id="id" 
              name="id"
              dense
              disabled
              v-model="bairro.id"
              label="ID"
            >
            </v-text-field>
          </v-col>
      </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field 
              id="nome" 
              name="nome"
              dense
              v-model="bairro.nome"
              label="Nome"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </form>
      <v-btn class="botoes" color="blue darken-1" text @click="cancel"> Cancelar </v-btn>
      <v-btn class="botoes" color="blue darken-1" text @click="save"> Salvar </v-btn>
    </v-card>
  </div>
</template>

<script>
import svcBairros from "../../services/svcBairros";
import svcCidades from "../../services/svcCidades";
import SvgIcon from '@jamescoyle/vue-icon';
import { decriptar, encriptar } from "../../config/cripto";
import { mdiSitemap } from '@mdi/js';

export default {
  components: {
        SvgIcon
    },
  data() {
    return {
      icone: mdiSitemap,
      bairro: {
        id: "",
        id_cidade: "",
        nome: "",
      },
      cidade: {
        id: "",
        nome: "",
        uf: "",
      },
      cidades: [],
      bairros: [],
    };
  },

  methods: {
    pesquisa(id) {
      svcBairros
        .pesquisar(id)
        .then((resp) => {
          this.bairro = resp.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    buscaCidade(id_cidade) {
        svcCidades
          .pesquisar(id_cidade)
          .then((resp) => {
            this.cidade = resp.data;
          })
          .catch((error) => {
            alert(error);
          });
      },

    cancel() {
      this.reposiciona();
    },

    save() {
      if (this.$route.fullPath.substring(0, 12) == "/bairros/new") {
        // Novo Municipio
        this.bairro.id_cidade = this.cidade.id;
        svcBairros
          .incluir(this.bairro)
          .then((resp) => {
            if (resp.status == 200) {
              this.bairro = resp.data;
              this.reposiciona();
            } else {
              alert("Erro inserindo novo bairro.");
            }
          })
          .catch((error) => {
            alert(error);
          });
      } else {
          svcBairros
            .alterar(this.bairro)
            .then((resp) => {
              if (resp.status != 200) {
                alert("Erro alterando dados do bairro.");
              }
            })
            .then(() => {
              this.reposiciona();
            })
            .catch((error) => {
              alert(error);
            });
      }
    },

    reposiciona() {
      this.$router.push("/bairros/" + encriptar(this.cidade.id));
    },
  },

  mounted() {
    this.buscaCidade(decriptar(this.$route.params.id_cidade));
    if (this.$route.fullPath.substring(0, 12) != "/bairros/new") {
      this.pesquisa(decriptar(this.$route.params.id))
    }
  },
};
</script>

<style scoped>
#formulario {
  max-width: 800px;
  margin: 0 auto;
}
#cartao {
  max-width: 800px;
  padding: 0px 50px 50px 50px;
  margin-top: 0px;
  margin-bottom: 120px;
  /* background-color: #dbdbdb; */
}
.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;
}
label {
  font-weight: bold;
  margin-bottom: 1px;
  color: #222;
  text-align: left;
}
.botoes {
  margin-top: 5px;
}
</style>
