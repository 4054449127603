<template>
    <div>
      <Header />
      <EditaCadastro />
    </div>
  </template>
  
  <script>
  import Header from "../../components/Header.vue"
  import EditaCadastro from "../../components/Bairros/EditaBairros.vue"
  import Footer from "../../components/Footer.vue"
  
  export default {
    components: {
      Header,
      EditaCadastro,
      Footer
    }
  }
  </script>
  